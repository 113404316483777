/* You can add global styles to this file, and also import other style files */

@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

/* / Define Css Varible */

@font-face {font-family:'NBK'; src:url('./assets/fonts/Nunito-Black.ttf'); font-weight:normal; font-style:normal;}
@font-face {font-family:'NBKI'; src:url('./assets/fonts/Nunito-BlackItalic.ttf'); font-weight:normal; font-style:italic;}
@font-face {font-family:'NB'; src:url('./assets/fonts/Nunito-Bold.ttf'); font-weight:bold; font-style:Bold;}
@font-face {font-family:'NBI'; src:url('./assets/fonts/Nunito-BoldItalic.ttf'); font-weight:bold; font-style:italic;}
@font-face {font-family:'NEB'; src:url('./assets/fonts/Nunito-ExtraBold.ttf'); font-weight:bold; font-style:normal;}
@font-face {font-family:'NEBI'; src:url('./assets/fonts/Nunito-ExtraBoldItalic.ttf'); font-weight:bold; font-style:italic;}
@font-face {font-family:'NEBL'; src:url('./assets/fonts/Nunito-ExtraLight.ttf'); font-weight:normal; font-style:normal;}
@font-face {font-family:'NEBLI'; src:url('./assets/fonts/Nunito-ExtraLightItalic.ttf'); font-weight:normal; font-style:italic;}
@font-face {font-family:'NI'; src:url('./assets/fonts/Nunito-Italic.ttf'); font-weight:normal; font-style:italic;}
@font-face {font-family:'NL'; src:url('./assets/fonts/Nunito-Light.ttf'); font-weight:normal; font-style:normal;}
@font-face {font-family:'NLI'; src:url('./assets/fonts/Nunito-LightItalic.ttf'); font-weight:normal; font-style:italic;}
@font-face {font-family:'NR'; src:url('./assets/fonts/Nunito-Regular.ttf'); font-weight:normal; font-style:normal;}
@font-face {font-family:'NSB'; src:url('./assets/fonts/Nunito-SemiBold.ttf'); font-weight:bold; font-style:normal;}
@font-face {font-family:'NSBI'; src:url('./assets/fonts/Nunito-SemiBoldItalic.ttf'); font-weight:bold; font-style:italic;}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html, body  {
    // padding-top: 4rem;
    font-family: 'NBK, NBKI, NB, NBI, NEB, NEB, NEBI, NEBL, NI, NL, NLI, NR, NSB, NSBI';
    width:100%;
    height: 100%;
    overflow: hidden;
    // overflow-y: hidden; /* Hide vertical scrollbar */
    // overflow-x: hidden; /* Hide horizontal scrollbar */
}

.mat-card {
  font-family: "NR" !important;
}

.font-family-NSB{
  font-family:'NSB';
}
.font-family-NB{
  font-family:'NB';
}
.starter-template {
    padding: 3rem 1.5rem;
    text-align: center;
}

// html {
//     position: relative;
//     min-height: 100%;
// }

.color-yellow {
    background-color: #ffb600;
}
.fixed-top {
    z-index: 0 !important;
}

h3{
  font-family: 'NEB';
}
.mat-button {
  font-family: 'NB';
}
.mat-spinner circle{
  width: 20px;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #ffb600;
}

button,
.mat-button:focus{
  outline: 0 !important;
}
.mat-drawer-content {
  overflow: unset !important;
}
.success-snackbar{
  background-color: #5cb85c;
  color:white;
}
.error-snackbar{
  background-color: #d9534f;
  color:#ffffff;
}
.btn-danger, .btn-danger:hover, .btn-danger:active, .btn-danger:not(:disabled) {
  color: #fff;
  // background-color: #111111;
  // border-color: #111111;
  background-color: #c82333;
  border-color: #bd2130;

}
.mat-form-field {
  font-size: 12px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0,0,0);
}
.radius-50{
  border-radius: 50px  !important;
}
.btn:focus {
  box-shadow: none !important;
}
.form-control:focus {
  color: #495057;
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
/* material form controls focus change */
.mat-input-element {
  caret-color: #000000;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: #ffb600;
    color: #000000;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled)
{
  color: #ffb600;
}
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: #ffb600;
    color: #000000;
}
.mat-form-field.mat-focused .mat-form-field-label, .mat-input-element,
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #000000;
}
/* / material form controls focus change */

.mat-button-focus-overlay { background-color: transparent!important; }
@media screen and (max-width: 768px) {
  .mat-drawer-content {
    overflow: auto !important;
  }
}

.btn-yolow-image{
  background-color: #ffb600;
  color: #000000;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
  flex: 1 1 100%;
  max-height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

/* scrollbar css */

::-webkit-scrollbar {width:10px!important;}
/* Track */
::-webkit-scrollbar-track {box-shadow:inset 0 0 2px #ccc!important; border-radius:10px!important;}

/* Handle */
::-webkit-scrollbar-thumb {background:#CFCFCF!important; border-radius:10px!important;}
